<template>
    <div id="app">
        <LangSwitchCom></LangSwitchCom>
        <NavbarCom></NavbarCom>
        <router-view class="content" ref="app"></router-view>
        <TabbarCom></TabbarCom>
    </div>
</template>

<script setup>
import { useStore } from "vuex"
import TabbarCom from "@/components/TabbarCom/TabbarCom.vue"
import LangSwitchCom from "@/components/LangSwitchCom/LangSwitchCom.vue"
import NavbarCom from "./components/NavbarCom/NavbarCom.vue";
import { ref, onMounted } from "vue"
import forceMobileMode from "./utils/forceMobileMode";
import i18n from "./i18n"

// MARK - Props
// ************************************************************************
const { t } = i18n.global
const store = useStore()


// MARK - LIFE CYCLE
// ************************************************************************
onMounted(() => {
    forceMobileMode()
    document.title = t('app.title')

    /*禁止ios缩放，双击和双指*/
    disableH5Zoom()

    // 定时抓取用户信息
    const intervalId = setInterval(function() {
        store.dispatch("FetchUserInfo")
    }, 5000); // 每5000毫秒 (1秒) 执行一次

})

// MARK - Methods
// ************************************************************************
/**
 * Function to disable zooming on touch events.
 *
 * @return {void}
 */
const disableH5Zoom = () => {
    window.onload = function() {
        document.addEventListener("touchstart", function(event) {
            if (event.touches.length > 1) {
                event.preventDefault();
            }
        });
        var lastTouchEnd = 0;
        document.addEventListener(
            "touchend",
            function(event) {
                var now = new Date().getTime();
                if (now - lastTouchEnd <= 300) {
                    event.preventDefault();
                }
                lastTouchEnd = now;
            },
            false
        );
        document.addEventListener("gesturestart", function(event) {
            event.preventDefault();
        });
    }
}


</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

html,
body,
ul,
li,
div,
h1 {
    padding: 0;
    margin: 0;
}


/* 禁止ios缩放 */
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select,
    textarea,
    input {
        font-size: 16px;
    }
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
    }
}
</style>
